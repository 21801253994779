// src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import "react-datetime/css/react-datetime.css";
import "moment/locale/en-gb"; // Import locale (change to your preferred locale)
import ContractTable from './components/ContractTable';
import CreateContact from './components/CreateContact';
import UpdateContact from './components/UpdateContact';
import { useNavigate } from 'react-router-dom';
import { tab } from '@testing-library/user-event/dist/tab';

const Contract = () => {

    const navigate = useNavigate();

    const handleClickedLogout = async (e) => {
        await supabase.auth.signOut();
        const { error } = await supabase.auth.signOut();

        if (!error) {
            navigate('/login'); // Navigate to login page after logout
        } else {
            console.error('Error logging out:', error.message);
        }

    }
    const [tabs, setTabs] = useState([
        { name: 'Embarkation Ladder', dbName: "embed_ladder_contracts", current: false },
        { name: 'Pilot Ladder', dbName: "pilot_ladder_contracts", current: false },
        { name: 'Firemans Outfit', dbName: "firemans_outfit_contracts", current: true }
    ])

    const [isNewAdded, setIsNewAdded] = useState(false);
    const [isCreateModelOpen, setIsCreateModelOpen] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [maxSerialNumber, setMaxSerialNumber] = useState(0);
    useEffect(() => {
        fetchContracts(tabs.filter((t) => t.current == true)[0].dbName);
    }, []);

    const fetchContracts = async (type) => {
        const { data, error } = await supabase.from(type).select('*').order('created_at', { ascending: false });;
        if (error) console.log('error', error);
        else setContracts(data);
        const maxSerialNumber = data.length == 0 ? 0 : Math.max(...data.map(item => item.serial_number));
        setMaxSerialNumber(maxSerialNumber);
    };

    const handleChangeContractType = (myTab) => {
        setTabs(tabs.map(tab => {
            tab.current = tab.name === myTab.name ? true : false;
            return tab;
        }));
        fetchContracts(myTab.dbName);
    }

    const handleNewAdded = () => {
        fetchContracts(tabs.filter((t) => t.current == true)[0].dbName);
        setIsNewAdded(false);
    }

    return (
        <div className='relative bg-gray-100 h-[100vh] overflow-hidden'>
            <div className="w-full flex flex-col items-center bg-white text-black rounded-md">
                <nav aria-label="Tabs" className="flex  space-x-4 cursor-pointer">
                    {tabs.map((tab) => (
                        <a
                            key={tab.name}
                            href={tab.href}
                            onClick={() => handleChangeContractType(tab)}
                            aria-current={tab.current ? 'page' : undefined}
                            className={`${tab.current && "underline decoration-teal-950"} text-gray-500 hover:text-gray-700 rounded-md px-3 py-2 text-sm font-medium`
                            }
                        >
                            {tab.name}
                        </a>
                    ))}
                    <a href="#" onClick={(e) => { handleClickedLogout() }} className="text-red-500 hover:text-red-700 rounded-md px-3 py-2 text-sm font-bold">Logout</a>
                </nav>
            </div>
            <button
                onClick={(e) => { setIsCreateModelOpen(true) }}
                type="button"
                className="m-2 rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                <span className='text-lg pr-1 '>+</span> Create New
            </button>
            <ContractTable contracts={contracts} tab={tabs.filter((t) => t.current == true)[0]} isNewAdded={handleNewAdded} />
            <CreateContact
                isNewAdded={handleNewAdded}
                tab={tabs.filter((t) => t.current == true)[0]}
                isOpen={isCreateModelOpen}
                setIsCreateModelOpen={(e) => { setIsCreateModelOpen(false) }}
                maxSerialNumber={maxSerialNumber}
            />

        </div>



    );
};


export default Contract;
