
// src/pages/Login.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import Datetime from 'react-datetime';
import "moment/locale/en-gb"; // Import locale (change to your preferred locale)
// src/pages/Dashboard.js



import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import "react-datetime/css/react-datetime.css";
import QRCode from 'qrcode';

moment.updateLocale("en-gb", {
    week: {
        dow: 1, // Monday is the first day of the week (0 = Sunday, 1 = Monday)
    },
});


const UpdateContact = (props) => {

    const { tab, isOpen, setIsCreateModelOpen, oldDate, serialNumber, oldLength, oldShipName, isNewAdded } = props;

    useEffect(() => {
        setLength(oldLength);
        setShipName(oldShipName);
        setDate(oldDate);
    }, [oldDate, oldLength, oldShipName]);
    const [date, setDate] = React.useState(oldDate);

    const [length, setLength] = React.useState(oldLength);
    const [shipName, setShipName] = React.useState(oldShipName);

    async function createPresignedUrl(fileName) {
        return `https://wltcjbizlsblgspstkfv.supabase.co/storage/v1/object/public/contracts/pdf/${fileName}`
    }

    const formatDate = (myDate) => {
        const date = new Date(myDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0'dan başlar, bu yüzden +1 ekliyoruz
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    async function modifyPdf(serialNumber, length, shipName, date, fileName, dbName) {
        const url = `https://wltcjbizlsblgspstkfv.supabase.co/storage/v1/object/public/contracts/pdf/empty/${dbName}.pdf`;
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

        // Yeni bir PDF belgesi oluşturun
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const pages = pdfDoc.getPages();
        const firstPage = pages[0];

        const { width, height } = firstPage.getSize();
        const TimesRomanBold = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        const Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);

        if (dbName === 'pilot_ladder_contracts') {
            firstPage.drawText(`${serialNumber}    ${length}    ${shipName}`, {
                x: 293,
                y: height - 495,
                size: 12,
                font: TimesRomanBold,
                color: rgb(1, 0, 0),
            });

            firstPage.drawText(`${formatDate(date)}`, {
                x: 183,
                y: height - 643,
                size: 10,
                font: Helvetica,
                color: rgb(0, 0, 0),
            });
        }

        if (dbName === 'embed_ladder_contracts') {
            firstPage.drawText(`${serialNumber}    ${length}    ${shipName}`, {
                x: 252,
                y: height - 495,
                size: 12,
                font: TimesRomanBold,
                color: rgb(1, 0, 0),
            });

            firstPage.drawText(`${formatDate(date)}`, {
                x: 183,
                y: height - 655,
                size: 10,
                font: Helvetica,
                color: rgb(0, 0, 0),
            });
        }

        if (dbName === 'firemans_outfit_contracts') {
            firstPage.drawText(`${formatDate(date)}`, {
                x: 175,
                y: height - 733,
                size: 10,
                font: Helvetica,
                color: rgb(0, 0, 0),
            });
        }

        const qrUrl = await createPresignedUrl(fileName);
        const qrCodeDataUrl = await QRCode.toDataURL(qrUrl);
        const qrImage = await pdfDoc.embedPng(qrCodeDataUrl);
        const qrWidth = 100;
        const qrHeight = 100;

        // 6. QR kodunu PDF'in sağ üst köşesine ekleyin
        firstPage.drawImage(qrImage, {
            x: width - qrWidth - 30,
            y: height - qrHeight - 30,
            width: qrWidth,
            height: qrHeight,
        });

        const pdfBytes = await pdfDoc.save();
        saveAs(new Blob([pdfBytes]), fileName);
        const { data, error } = await supabase.storage
            .from('contracts/pdf')
            .upload(fileName, new Blob([pdfBytes]), {
                contentType: 'application/pdf',
            });


        return qrUrl;
    }

    function simplifyKey(filename) {
        const charMap = {
            'ı': 'i',
            'İ': 'I',
            'ö': 'o',
            'Ö': 'O',
            'ü': 'u',
            'Ü': 'U',
            'ş': 's',
            'Ş': 'S',
            'ç': 'c',
            'Ç': 'C',
            'ğ': 'g',
            'Ğ': 'G',
            ' ': '_',  // Replace spaces with underscores
        };

        // Use the map to replace characters in the filename
        const cleanedFilename = filename
            .split('')
            .map(char => charMap[char] || char)  // Replace if in the map, otherwise keep the original character
            .join('')
            .replace(/[^a-zA-Z0-9._-]/g, '_'); // Replace remaining special characters with underscores

        return cleanedFilename;
    }

    const handleClickedCreateContract = async () => {
        const structuredDate = formatDate(date);
        const fileName = simplifyKey(`DOC ${tab.name} No. ${serialNumber} ${length.replace(/\//g, '')} ${shipName} ${structuredDate}.pdf`);
        const qrUrl = await modifyPdf(serialNumber, length, simplifyKey(shipName), date, simplifyKey(fileName), tab.dbName);

        const { data, error } = await supabase.from(tab.dbName).update([
            { length, ship_name: simplifyKey(shipName), date, file_name: fileName, url: qrUrl, date: structuredDate, serial_number: serialNumber },
        ]).eq('serial_number', serialNumber).select('*');

        if (error) {
            console.error('Error inserting contract:', error);
            alert('Failed to create contract. Please try again.');
        } else {
            setIsCreateModelOpen(false);
            isNewAdded(true);
            clearInputs();
        }
    };


    const clearInputs = () => {
        setLength('');
        setShipName('');
        setDate('');
    };

    return (

        isOpen && <div className='absolute top-0 right-0 flex flex-col w-[100%] overflow-hidden h-[100vh] backdrop-blur-md flex justify-center items-center'>
            <div className='w-full md:w-[30%]  text-right '>
                <button className="fill-black hover:fill-white hover:bg-black border p-1 rounded-md" onClick={(e) => { setIsCreateModelOpen(false) }}>
                    <svg className='w-[15px] h-[15px]' height="800px" width="800px"
                        viewBox="0 0 490 490">
                        <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 
	489.292,457.678 277.331,245.004 489.292,32.337 "/>
                    </svg>

                </button>
            </div>

            <div className='w-full p-3 bg-white items-center justify-center md:w-[30%] rounded-md border-2'>
                <div className='m-2'>
                    <label htmlFor="contract_id" className="block text-sm font-medium leading-6 text-gray-900">
                        Date
                    </label>
                    <div className="">
                        <Datetime value={date} onChange={(date) => setDate(date)} locale="en-gb" timeFormat={false} dateFormat={"DD.MM.yyyy"} initialValue={new Date()} className="border block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 " />
                    </div>
                </div>
                <div className='m-2'>
                    <label htmlFor="contract_id" className="block text-sm font-medium leading-6 text-gray-900">
                        Serial Number
                    </label>
                    <div>
                        <input
                            id="serial_number"
                            name="text"
                            type="text"
                            disabled={true}
                            value={serialNumber}
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className='m-2'>
                    <label htmlFor="length" className="block text-sm font-medium leading-6 text-gray-900">
                        Length
                    </label>
                    <div >
                        <input
                            id="length"
                            name="text"
                            type="text"
                            value={length}
                            onChange={(e) => setLength(e.target.value)}
                            placeholder=""
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className='m-2'>
                    <label htmlFor="ship_name" className="block text-sm font-medium leading-6 text-gray-900">
                        Ship Name
                    </label>
                    <div>
                        <input
                            id="ship_name"
                            name="text"
                            type="text"
                            value={shipName}
                            onChange={(e) => setShipName(e.target.value)}
                            placeholder=""
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className='m-2'>
                    <label htmlFor="ship_name" className="block text-sm font-medium leading-6 text-gray-900">
                        Contract Type
                    </label>
                    <div>
                        <input
                            id="contract-type"
                            name="text"
                            type="text"
                            value={tab.name}
                            disabled={true}
                            placeholder=""
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>


                <button
                    onClick={handleClickedCreateContract}
                    type="button"
                    className="m-2 rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Update Contract
                </button>
            </div>
        </div>

    )
}

export default UpdateContact;