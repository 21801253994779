// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login.js';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Contract from './pages/Contract';

function App() {
  return (

    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/dashboard" element={<ProtectedRoute component={Contract} />} />
        {/* <Route path="/contract/:id?" element={<ProtectedRoute component={Contract} />} /> */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;

// ProtectedRoute component for authentication check
function ProtectedRoute({ component: Component }) {
  const isAuthenticated = localStorage.getItem('supabase.auth.token'); // Check if user is authenticated
  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
}
